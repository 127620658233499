<template>
    <div class="columns is-centered">
        <div class="column is-three-quarters-desktop is-full-touch">
            <enso-form class="box form-box has-background-light raises-on-hover" ref="variableForm"
            @loaded="afterInit">
                <template #type="props">
                    <form-field has-tooltip-right v-bind="props"
                                @selection="toggleType"/>
                </template>
                <template #dependent="props">
                    <form-field has-tooltip-right v-bind="props"
                                @selection="toggleDependent"/>
                </template>
                <template #replicate="props">
                    <form-field has-tooltip-right v-bind="props"
                                @selection="toogleByReplicate"/>
                </template>
            </enso-form>
        </div>
    </div>
</template>

<script>
import { EnsoForm, FormField } from '@enso-ui/bulma';

export default {
    name: 'Edit',

    components: { EnsoForm, FormField },

    data() {
        return {
            ready: false,
        };
    },
    computed: {
        form() {
            return this.ready
                ? this.$refs.variableForm
                : {};
        },
    },
    mounted() {
        this.ready = true;
    },
    methods: {
        toggleDependent(dependent) {
            console.log('toggleDependent', dependent);
            if (!dependent) {
                console.error('Dependent object is null or undefined');
                return;
            }
            if (dependent.value !== null && dependent.value === 1) {
                this.$refs.variableForm.field('variable_id').meta.hidden = false;
            } else if (dependent.value !== null && dependent.value === 0) {
                this.$refs.variableForm.field('variable_id').meta.hidden = true;
            }
        },
        toogleByReplicate(replicate) {
            if (!replicate) {
                console.error('replicate object is null or undefined');
                return;
            }
            if (this.$refs.variableForm && this.$refs.variableForm.field) {
                if (replicate.value !== null && replicate.value === 1) {
                    this.$refs.variableForm.field('profiles_replicate').meta.hidden = false;
                } else if (replicate.value !== null && replicate.value === 0) {
                    this.$refs.variableForm.field('profiles_replicate').meta.hidden = true;
                }
            } else {
                console.error('Variable for variableForm field is not defined');
            }
        },
        toggleType(type) {
            console.log('toggleType', type);
            if (!type) {
                console.error('type object is null or undefined');
                return;
            }
            if (type.value !== null && type.value === true) {
                this.$refs.variableForm.field('description').meta.hidden = false;
                this.$refs.variableForm.field('points').meta.hidden = false;
            } else if (type.value !== null && type.value === false) {
                this.$refs.variableForm.field('description').meta.hidden = true;
                this.$refs.variableForm.field('points').meta.hidden = true;
            }
        },
        afterInit(form) {
            if (!form) {
                console.error('type object is null or undefined');
                return;
            }
            if (this.$refs.variableForm.field('type').value !== null
                && this.$refs.variableForm.field('type').value === 'points_campaigns') {
                this.$refs.variableForm.field('description').meta.hidden = false;
                this.$refs.variableForm.field('points').meta.hidden = false;
            } else if (this.$refs.variableForm.field('type').value !== null
                && this.$refs.variableForm.field('type').value !== 'points_campaigns') {
                this.$refs.variableForm.field('description').meta.hidden = true;
                this.$refs.variableForm.field('points').meta.hidden = true;
            }
            if (this.$refs.variableForm.field('dependent').value !== null
                && this.$refs.variableForm.field('dependent').value === 1) {
                this.$refs.variableForm.field('variable_id').meta.hidden = false;
            } else if (this.$refs.variableForm.field('dependent').value !== null
                && this.$refs.variableForm.field('dependent').value === 0) {
                this.$refs.variableForm.field('variable_id').meta.hidden = true;
            }
            if (this.$refs.variableForm.field('replicate').value !== null
                && this.$refs.variableForm.field('replicate').value === 1) {
                this.$refs.variableForm.field('profiles_replicate').meta.hidden = false;
            } else if (this.$refs.variableForm.field('replicate').value !== null
                && this.$refs.variableForm.field('replicate').value === 0) {
                this.$refs.variableForm.field('profiles_replicate').meta.hidden = true;
            }
        },
    },
};
</script>

<style lang="scss">
</style>
